export default {
  api: 'https://api.dev.cx.curvo.cloud/graphql',
  agGridLicense:
    '',
  supplierPortalBaseUrl: 'https://curvolabsdev.appiancloud.com',
  authDomain: 'dev-ph31cu-z.us.auth0.com',
  authClientId: 'nrqm4MJW9d0tnKxWfJ8HVDzzpt9l9oDX',
  authAudience: 'https://dev-ph31cu-z.us.auth0.com/api/v2/',
  useHubspot: false,
  useHeap: false,
  launchDarklyClientId:
    '642a8c3f664163131a63d713',
}
